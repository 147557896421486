.extra-resources-card {
  background: var(--theme-pale);
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-top: var(--gap);
  margin-bottom: var(--gap);

  &__header {
    background: var(--theme-pale);
    padding: var(--gap);
    display: flex;
    align-items: center;

    & > img {
      flex-shrink: 0;
      margin-right: var(--gap);
      max-width: 50px;
    }
  }

  &__content {
    padding: var(--gap-double);
  }
}
