.service-detail {
  border: 1px solid var(--grey-light);
  padding: var(--gap-double) var(--gap);
  border-radius: var(--border-radius);

  &__header-image {
    border: 1px solid var(--grey-light);
    background: var(--white);
    padding: var(--gap-double);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--gap);
  }

  &__header,
  &__location {
    a {
      text-decoration: none;

      &:hover,
      &:focus {
      text-decoration: underline;
      }
    }
  }

  &__location {
    padding: var(--gap-double);
    border: 1px solid var(--grey-light);
    border-radius: var(--border-radius-sm);
    margin-bottom: var(--gap);

    header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    & > div {
      margin-right: var(--gap);
    }
  }

  address {
    font-style: normal;
  }

  &__tag {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: var(--gap);
    font-size: var(--fz-sm);
    background-color: var(--theme-pale);
    border-radius: var(--border-radius);
    padding: 0.4em 1em;
    font-weight: bold;
    text-align: center;
  }

  &__main-opening-hours {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    & > div {
      margin-bottom: var(--gap-double);
    }
  }
}
