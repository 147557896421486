.text {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}

.fs {
  &-normal {
    font-style: normal;
  }
  &-italic {
    font-style: italic;
  }
}

.fw {
  &-normal {
    font-weight: 400;
  }
  &-bold {
    font-weight: 700;
  }
}

.ff {
  &-base {
    font-family: var(--base-font-stack);
  }
  &-title {
    font-family: var(--title-font-stack);
  }
}

.fz {
  &-h1 {
    font-size: var(--fz-h1);
  }
  &-h2 {
    font-size: var(--fz-h2);
  }
  &-h3 {
    font-size: var(--fz-h3);
  }
  &-h4 {
    font-size: var(--fz-h4);
  }
  &-h5 {
    font-size: var(--fz-h5);
  }
  &-base {
    font-size: var(--fz-base);
  }
  &-sm {
    font-size: var(--fz-sm);
  }
}