.service-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  --aside-width: 530px;

  &__main {
    padding: var(--gap-double);
    flex-basis: calc(100% - var(--aside-width) - 20px);
    flex-grow: 1;
    margin-bottom: var(--gap);
    min-width: 50%;
  }

  &__contact-links {
    a {
      text-decoration: none;

      &:hover,
      &:focus {
      text-decoration: underline;
      }
    }
  }

  &__aside {
    padding: var(--gap-double);
    flex-basis: var(--aside-width);
    min-width: 280px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--gap);

    & > div {
      margin-right: var(--gap);
    }
  }

  address {
    font-style: normal;
  }

  &__tag {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: var(--gap);
    font-size: var(--fz-sm);
    background-color: var(--theme-pale);
    border-radius: var(--border-radius);
    padding: 0.4em 1em;
    font-weight: bold;
    text-align: center;
  }
}
