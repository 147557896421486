.text {
  &-theme {
    color: var(--theme);
  }
  &-theme-pale {
    color: var(--theme-pale);
  }
  &-theme-dark {
    color: var(--theme-dark);
  }
  &-white {
    color: var(--white);
  }
  &-black {
    color: var(--black);
  }
  &-grey-light {
    color: var(--grey-light);
  }
  &-grey-mid {
    color: var(--grey-mid);
  }
  &-grey-dark {
    color: var(--grey-dark);
  }
}

.bg {
  &-theme {
    background-color: var(--theme);
  }
  &-theme-pale {
    background-color: var(--theme-pale);
  }
  &-theme-dark {
    background-color: var(--theme-dark);
  }
  &-white {
    background-color: var(--white);
  }
  &-black {
    background-color: var(--black);
  }
  &-grey-light {
    background-color: var(--grey-light);
  }
  &-grey-mid {
    background-color: var(--grey-mid);
  }
  &-grey-dark {
    background-color: var(--grey-dark);
  }
}

.set-theme {
  &-purple {
    --theme: var(--purple);
    --theme-pale: var(--purple-pale);
    --theme-dark: var(--purple-dark);
  }
  &-green {
    --theme: var(--green);
    --theme-pale: var(--green-pale);
    --theme-dark: var(--green-dark);
  }
  &-orange {
    --theme: var(--orange);
    --theme-pale: var(--orange-pale);
    --theme-dark: var(--orange-dark);
  }
  &-blue {
    --theme: var(--blue);
    --theme-pale: var(--blue-pale);
    --theme-dark: var(--blue-dark);
  }
  &-pink {
    --theme: var(--pink);
    --theme-pale: var(--pink-pale);
    --theme-dark: var(--pink-dark);
  }
  &-red {
    --theme: var(--red);
    --theme-pale: var(--red-pale);
    --theme-dark: var(--red-dark);
  }
}

/* WP outputted colour classes */
.has-white-color {
  color: var(--white);
}
.has-black-color {
  color: var(--black);
}
.has-purple-color {
  color: var(--purple);
}
.has-purple-dark-color {
  color: var(--purple-dark);
}
.has-green-color {
  color: var(--green);
}
.has-green-pale-color {
  color: var(--green-pale);
}
.has-green-dark-color {
  color: var(--green-dark);
}
.has-orange-color {
  color: var(--orange);
}
.has-orange-pale-color {
  color: var(--orange-pale);
}
.has-orange-dark-color {
  color: var(--orange-dark);
}
.has-blue-color {
  color: var(--blue);
}
.has-blue-pale-color {
  color: var(--blue-pale);
}
.has-blue-dark-color {
  color: var(--blue-dark);
}
.has-pink-color {
  color: var(--pink);
}
.has-pink-pale-color {
  color: var(--pink-pale);
}
.has-pink-dark-color {
  color: var(--pink-dark);
}
.has-red-color {
  color: var(--red);
}
.has-red-dark-color {
  color: var(--red-dark);
}

.has-white-background-color {
  background-color: var(--white);
}
.has-black-background-color {
  background-color: var(--black);
}
.has-purple-background-color {
  background-color: var(--purple);
}
.has-purple-dark-background-color {
  background-color: var(--purple-dark);
}
.has-green-background-color {
  background-color: var(--green);
}
.has-green-pale-background-color {
  background-color: var(--green-pale);
}
.has-green-dark-background-color {
  background-color: var(--green-dark);
}
.has-orange-background-color {
  background-color: var(--orange);
}
.has-orange-pale-background-color {
  background-color: var(--orange-pale);
}
.has-orange-dark-background-color {
  background-color: var(--orange-dark);
}
.has-blue-background-color {
  background-color: var(--blue);
}
.has-blue-pale-background-color {
  background-color: var(--blue-pale);
}
.has-blue-dark-background-color {
  background-color: var(--blue-dark);
}
.has-pink-background-color {
  background-color: var(--pink);
}
.has-pink-pale-background-color {
  background-color: var(--pink-pale);
}
.has-pink-dark-background-color {
  background-color: var(--pink-dark);
}
.has-red-background-color {
  background-color: var(--red);
}
.has-red-dark-background-color {
  background-color: var(--red-dark);
}