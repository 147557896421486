.ncb-preview {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--white);
  border-radius: var(--border-radius);
  
  &__image {
    position: relative;
    
    img {
      width: 100%;
      display: block;
    }
  }

  &__tag {
    position: absolute;
    bottom: 3px;
    left: 3px;
    display: inline-flex;
    background-color: white;

    span {
      background-color: var(--theme-pale);
      padding: 0.2rem 1rem;
    }
  }

  &__content {
    border: 3px solid var(--theme);
    border-top: none;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    padding: var(--gap);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    margin: var(--gap) 0;
  }

  &__btn {
    margin-top: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
