/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
  color: var(--black);
  background-color: var(--white);
  font-size: 1em;
  line-height: 1.5;
  font-family: var(--base-font-stack);
  overflow-x: hidden;

  @media (--tiny) {
    font-size: 1.125em;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::-moz-selection {
  background: var(--purple-dark);
  color: white;
  text-shadow: none;
}

::selection {
  background: var(--purple-dark);
  color: white;
  text-shadow: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;

  [href^="tel"] {
    text-decoration: inherit;
    color: inherit;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 700;
  font-family: var(--title-font-stack);
}

figure {
  margin: 0;
}
