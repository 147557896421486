.social-share-links {

  & > p {
    font-family: var(--title-font-stack);
    font-weight: bold;
    margin-bottom: var(--gap);
  }

  a {
    display: inline-block;
    text-decoration: none;
    margin-right: var(--gap-half);

    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }

}