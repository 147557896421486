.help-card {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: var(--white);
  padding: var(--gap);
  border: 2px solid var(--theme);
  border-radius: var(--border-radius);

  &>img {
    flex-shrink: 1;
    margin-right: var(--gap);
    margin-bottom: var(--gap);
    flex-basis: 90px;
    min-width: 60px;
  }

  &__content {
    flex-basis: calc(100% - var(--gap) - 100px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    .btn {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
}
