.site-menu {
  display: flex;
  align-items: stretch;
  font-family: var(--ff-title);
  font-weight: bold;
  flex-direction: column;

  & > li {
    display: flex;
    margin-right: var(--gap-half);

    & > a {
      text-decoration: none;
      padding: var(--gap-half) var(--gap-half);

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    &.current_page_item,
    &.current-page-ancestor {
      & > a {
        color: var(--purple-dark);
      }
    }
  }

  @media (--md) {
    flex-direction: row;
  }
}
