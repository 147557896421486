.links-grid {
  list-style: none;
  padding: 0;

  & > li {
    list-style: none;
    margin-bottom: var(--gap-double);
    width: 100%;
  }

  @media (--md) {
    margin-left: calc(var(--gap-double) * -1);
    margin-right: calc(var(--gap-double) * -1);
    display: flex;
    flex-wrap: wrap;

    & > li {
      margin-left: var(--gap-double);
      margin-right: var(--gap-double);
      flex-grow: 0;
      width: calc(50% - calc(var(--gap-double) * 2));
    }
  }

  @media (--xl) {
    & > li {
      width: calc(33% - calc(var(--gap-double) * 2));
    }
  }

  @supports (display: grid) {
    display: grid;
    gap: var(--gap-double) calc(var(--gap-double) * 2);
    margin-bottom: var(--gap-double);

    grid-template-columns: 1fr;

    & > li {
      margin: 0;
      width: 100%;
    }

    @media (--tiny) {
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }

    @media (--md) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__link {
    box-sizing: border-box; /* Necessary for cms apparently */
    height: 100%;
    background: var(--white);
    padding: var(--gap-double);
    border: 2px solid var(--theme);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--title-font);
    font-weight: bold;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    & > span {
      flex-grow: 1;
      text-align: left;
    }

    & > svg {
      flex-shrink: 0;
      margin-left: var(--gap);
      color: var(--theme);
    }
  }

  &__image {
    flex-shrink: 0;
    margin-right: 6%;
    max-width: 50px;

    @media (--tiny) {
      width: 25%;
      max-width: 100px;
    }
  }
}
