/* Shown in the sidebar - a list of section pages */

.section-nav {
  margin-bottom: var(--gap-double);

  &__title {
    margin-bottom: var(--gap);
    padding-bottom: var(--gap);
    border-bottom: 1px solid currentColor;
  }

  &__item {
    border-bottom: 1px solid var(--grey-mid);
    padding-bottom: var(--gap-half);
    margin-bottom: var(--gap-half);

    a {
      display: inline-block;
      text-decoration: underline;
      padding: calc(var(--gap-half) / 2) 0;
      padding-left: var(--gap);

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &.is-section-parent {
      border-color: var(--black);
      padding-bottom: var(--gap);
      a {
        font-size: var(--fz-h4);
        font-weight: bold;
        padding-left: 0;
      }
    }

    &.is-current-page {
      a {
        border-left: 4px solid var(--theme);
        font-weight: bold;
        text-decoration: none;
        padding-left: var(--gap);
      }
    }
  }
}
