.sidebar-box {
  border: 2px solid var(--theme-dark);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  background-color: var(--white);
  margin-bottom: var(--gap-double);


  &__title {
    margin-bottom: var(--gap);
    padding: var(--gap);
    color: var(--white);
    background-color: var(--theme-dark);
  }

  a {
    margin: var(--gap);
    padding: var(--gap);
    display: block;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
