:root {
    /* colours */
    --white: #fff;
    --black: #464646;
    --black-true: #000;
    --grey-xxlight: #eeeeee;
    --grey-xlight: #D5D5D5;
    --grey-light: #ccc;
    --grey-mid: rgb(112, 112, 112, 0.5);
    --grey-dark: #333;

    --purple: #987AC6;
    --purple-pale: #987AC644;
    --purple-dark: #7E59B8;
    --green: #84CDBD;
    --green-pale: #84CDBD66;
    --green-dark: #65C1AC;
    --green-accent: #2C816D;
    --orange: #FFBD99;
    --orange-pale: #FFBD9944;
    --orange-dark: #FFAD7F;
    --blue: #94B9E8;
    --blue-pale: #94B9E833;
    --blue-dark: #79A7E2;
    --pink: #D6A6E7;
    --pink-pale: #D6A6E726;
    --pink-dark: #CC90E1;
    --red: #E98FA9;
    --red-pale: #E3739333;
    --red-dark: #E37393;

    --accessibility-green: #009639;
    --urgent-help-red: #E6054C;
    --yellow: #FDDC28;

    --theme: var(--green);
    --theme-pale: var(--green-pale);
    --theme-dark: var(--green-dark);

    /* size */
    --gap: 0.8rem;
    --gap-half: calc(var(--gap) / 2);
    --gap-double: calc(var(--gap) * 2);
    --row: 82rem;
    --row-lg: 64rem;
    --row-md: 46rem;
    --row-sm: 36rem;
    --row-xs: 24rem;

    /* font */
    --base-font-stack: "soleil", "Helvetica Neue", "Helvetica", sans-serif;
    --title-font-stack: "tondo", "Helvetica Neue", "Helvetica", sans-serif;

    --fz-h1: 2rem;
    --fz-h2: 1.5rem;
    --fz-h3: 1.25rem;
    --fz-h4: 1.175rem;
    --fz-base: 1rem;
    --fz-sm: 0.885rem;

    @media (--sm) {
        --fz-h1: 2.4rem;
        --fz-h2: 2.2rem;
        --fz-h3: 1.725rem;
        --fz-h4: 1.35rem;
        --fz-h5: 1.1rem;
    }

    /* misc */
    --border-radius: 1.8rem;
    --border-radius-sm: 0.9rem;
    --border-radius-xs: 0.5rem;
    --border-standard: 2px solid var(--theme);
}