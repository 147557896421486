.home {
  &-se-blocks {
    & > li {
      margin-bottom: var(--gap-double);
    }

    @media (--md) {
      display: flex;

      & > li {
        margin-left: var(--gap-half);
        margin-right: var(--gap-half);
        width: calc(33.33% - var(--gap));

        & > .links-block {
          height: 100%;
        }
      }
    }
  }

  &-help-links {
    & > li {
      margin-bottom: var(--gap-double);
    }

    @media (--sm) {
      display: flex;
      margin-left: calc(var(--gap) * -1);
      margin-right: calc(var(--gap) * -1);

      & > li {
        margin-left: var(--gap);
        margin-right: var(--gap);
        width: calc(33.33% - var(--gap-double));

        & > .help-card {
          height: 100%;
        }
      }
    }
  }

  &-about {
    @media (--md) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > article {
        flex-shrink: 0;
        padding-right: var(--gap);
        max-width: 500px;
      }

      & > figure {
        flex-grow: 1;
        max-width: 60%;
        flex-basis: 60%;
      }
    }
  }

  &-news {
    padding-top: var(--gap-double);
    padding-bottom: calc(var(--gap-double) * 2);

    .latest-posts {
      & > li {
        margin-bottom: var(--gap-double);
      }
    }

    @media (--sm) {
      .latest-posts {
        display: flex;

        & > li {
          flex-basis: 33.3%;
          margin-right: var(--gap);

          & > article {
            height: 100%;
          }
        }
      }
    }

    @media (--md) {
      display: flex;
      flex-wrap: wrap;

      & > * {
        flex-grow: 1;
        flex-shrink: 0;
      }

      .latest-posts {
        flex-basis: calc(70% - var(--gap-double));
        min-width: 480px;
      }

      .newsletter-signup {
        min-width: 340px;
        flex-basis: calc(30% - var(--gap-double));
      }
    }

    @media (--lg) {
      padding-top: calc(var(--gap-double) * 2);

      .latest-posts {
        padding-right: 4rem;
      }
    }
  }
}
