.form {
  &__label {
    display: block;
    margin-bottom: 0.25rem;
  }

  &__input,
  &__select {
    box-shadow: none;
    display: block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin: 0 0 var(--gap) 0;
    padding: 0.2rem var(--gap);
    font-size: var(--fz-sm);
    font-family: var(--base-font);
    color: var(--black);
    border: 1px solid var(--grey-mid);
    border-radius: 10px;
    background: none;
    outline: none;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 2px var(--theme);
    }

    &.error {
      border-color: var(--urgent-help-red);
    }
    
    &--textarea {
      height: 8rem;
      resize: vertical;
      line-height: 1.4;
      padding-top: var(--gap-half);
    }
  }

  &__select {
    min-width: 200px;
    padding: 0 var(--gap-double) 0 var(--gap);
    border-radius: var(--border-radius);
    appearance: none;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.716' height='13.507' viewBox='0 0 20.716 13.507'%3E%3Cpath d='M17.183 3.533a63.388 63.388 0 00-6.934 7.275c-.065.087-.129.2-.237.2a.317.317 0 01-.219-.143L3.525 3.533' fill='none' stroke='%2365c1ac' stroke-linecap='round' stroke-linejoin='round' stroke-width='5'/%3E%3C/svg%3E")
    right 10px center / 20px 12px no-repeat var(--white);
    cursor: pointer;
  }

  &__errors {
    border: 2px solid var(--urgent-help-red);
    color: var(--urgent-help-red);
    list-style: disc;
    padding: var(--gap);
    font-size: var(--fz-sm);

    li {
      margin-left: var(--gap);
    }
  }

  &__disclaimer-and-submit {
    & > * {
      margin-bottom: var(--gap);
    }

    @media (--sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > button {
        flex-shrink: 0;
        margin-left: var(--gap-double);
      }
    }
  }

  .g-recaptcha {
    margin-bottom: $gap-half;
  }

  .fields-flex {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin: 0 0.5rem;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: calc(50% - 1rem);
      min-width: 210px;

      select {
        margin-bottom: 1rem;
      }
    }

    &--3 {
      & > div {
        flex-basis: calc(33% - 1rem);
      }
    }
  }
}

.error-text {
  color: var(--urgent-help-red);
}