.btn {
  display: inline-flex;
  background: transparent;
  color: var(--black);
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  padding: var(--gap) calc(var(--gap) * 2);
  border: 3px solid var(--theme);
  border-radius: var(--border-radius);
  outline: none;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px var(--theme);
  }

  &--urgent-help {
    border-color: var(--urgent-help-red);
    background: var(--urgent-help-red);
    color: var(--white);
    padding: var(--gap-half) var(--gap);

    &:hover,
    &:focus {
      background: var(--yellow);
      border-color: #000;
      color: #000;
      box-shadow: none;
    }

    @media (--lg) {
      padding: var(--gap) calc(var(--gap) * 2);
    }
  }

  &--accessibility {
    border-color: var(--accessibility-green);
    background: var(--accessibility-green);
    color: var(--white);
    padding: var(--gap-half);
    gap: var(--gap-half);
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;

    &:hover,
    &:focus {
      background: var(--yellow);
      border-color: #000;
      color: #000;
      box-shadow: none;
    }

    & > span {
      display: none;
    }

    @media (--lg) {
      padding: var(--gap) calc(var(--gap) * 2);
      width: auto;
      height: auto;

      & > span {
        display: inline;
      }
    }
  }
}

.feedback-btn {
  position: fixed;
  bottom: calc(-1 * var(--gap-half));
  left: var(--gap);
  background-color: var(--purple-dark);
  display: inline-flex;
  align-items: center;
  padding: var(--gap-half) var(--gap) var(--gap);
  text-decoration: none;
  font-family: var(--title-font-stack);
  font-weight: bold;
  color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: all 0.24s ease;

  &:hover,
  &:focus {
    transform: translateY(calc(-1 * var(--gap-half)));
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  }

  & > svg {
    margin-right: var(--gap-half);
  }

  .page-template-feedback & {
    display: none;
  }
}

.back-link {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  margin-top: var(--gap);
  margin-bottom: var(--gap);

  & > svg {
    margin-right: var(--gap);
  }
}
