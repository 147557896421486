.page-heading {
  padding: var(--gap-double) 0;
  background: var(--theme-pale);
  box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.6);
  position: relative;

  & > .wrapper {
    padding-left: var(--gap);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title {
    font-size: var(--fz-h2);
    line-height: 1.2;
  }

  &__curve-wrap {
    max-width: 100%;
    overflow: hidden;
  }

  &__curve {
    display: block;
    width: 1600px;
    margin-left: calc(50% - 800px);
    margin-bottom: var(--gap-double);
    color: var(--theme-pale);
    overflow: hidden;

    &--up {
      margin-bottom: -1rem;
    }

    @media (--xl) {
      width: 100%;
      margin-left: 0;
    }
  }

  @media (--xl) {
    & > .wrapper {
      flex-wrap: nowrap;
    }

    &__title {
      font-size: var(--fz-h1);
    }
  }
}
