.services-directory {
  &__titles {
    display: flex;
    margin-bottom: var(--gap);
    > * {
      width: 32%;
      &:nth-child(2) {
        width: 31%;
        display: none;
        @media (--lg) {
          display: block;
        }
      }
      &:last-child {
        width: 37%;
        display: none;
        @media (--lg) {
          display: block;
        }
      }
    }
  }
  &__intro {
    border-top: 1px solid var(--grey-mid);
    padding-top: var(--gap);
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: var(--gap-double);
    margin-left: -1rem;

    @media (--sm) {
      margin: auto;
      justify-content: center;
    }

    @media (--md) {
      margin-left: -1rem;
      justify-content: flex-start;
    }

    & label {
      font-family: var(--title-font-stack);
      font-weight: bold;
      font-size: var(--fz-h3);
      margin-right: var(--gap);
      margin-bottom: var(--gap);
      margin-top: auto;
      display: inline-flex;
      color: var(--green-accent);
    }

    & > select {
      flex-basis: 400px;
    }

    .error-text {
      position: absolute;
    }

    .btn {
      margin-left: var(--gap);
      margin-bottom: var(--gap);
      display: none;
    }
  }

  &__box {
    flex: 0 1 584px;
    min-height: 248px;
    background-color: var(--grey-xxlight);
    padding: var(--gap) calc(var(--gap) * 2);
    margin: var(--gap);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: var(--border-radius-sm);
    .form__input {
      border-radius: var(--border-radius);
      background-color: white;
    }

    p {
      margin-bottom: var(--gap);
    }
  }

  &__box-input-field {
    display: flex;

    .btn {
      margin-bottom: 0;
      height: 50px;
      line-height: 16px;
    }
  }

  &__box-services {
    .btn {
      display: inline-flex;
    }
  }

  &__box-input {
    display: flex;
  }

  &__title {
    font-size: var(--fz-h3);
  }

  &__results {
    margin-bottom: calc(var(--gap) * 3);
  }
}

.no-js .services-directory__form .btn,
.no-js .error-text {
  display: inline-flex;
}

.no-js .error-text {
  position: static;
}
