.radio-list {
  &__item {
    margin-top: var(--gap-half);
    margin-bottom: var(--gap-half);
    font-size: var(--fz-h5);
    display: flex;
    align-items: center;

    input {
      margin: 0;
      padding: 0;
      appearance: none;
      position: relative;
      border-radius: 50%;
      background: var(--white);
      border: 1px solid var(--grey-mid);
      vertical-align: middle;
      width: 1em;
      height: 1em;
      cursor: pointer;
      outline-color: var(--theme);

      &:after {
        content: "";
        background: var(--black);
        border-radius: 50%;
        position: absolute;
        height: 0.5em;
        width: 0.5em;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0);
        transition: all 0.2s ease-in-out;
      }

      &:checked {
        &:after {
          transform: translateX(-50%) translateY(-50%) scale(1);
        }
      }
    }

    label {
      margin-left: var(--gap);
    }
  }
}
