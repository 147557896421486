.bg {
  &-bow-up,
  &-bow-down {
    background: var(--blue-pale);

    @media (--md) {
      background-color: transparent;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1600 488.274' transform='rotate(180)'%3E%3Cpath d='M0 452.757s379.9 48.344 928.85 32.222S1600 446.257 1600 446.257v-435.1S626.991 76.493 0-.003z' fill='%23eaf1fb'/%3E%3C/svg%3E");
      background-size: 1600px 100%;
      background-position: center;
      background-repeat: no-repeat;
    }

    @media (--xl) {
      background-size: 100% 100%;
    }
  }

  &-bow-down {
    @media (--md) {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1600 488.274'%3E%3Cpath d='M0 452.757s379.9 48.344 928.85 32.222S1600 446.257 1600 446.257v-435.1S626.991 76.493 0-.003z' fill='%23eaf1fb'/%3E%3C/svg%3E");
    }
  }
  &-color-blue-pale {
    background-color: var(--blue-pale);
  }
}