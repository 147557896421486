.table-of-contents {
  border: 2px solid var(--theme);
  border-left-width: 6px;
  padding: var(--gap);
  padding-bottom: var(--gap-half);
  margin-bottom: var(--gap);

  &__title {
    font-family: var(--title-font-stack);
    font-weight: bold;
    margin-top: 0;
  }

  &__list a {
    display: inline-block;
    margin-bottom: var(--gap);
    padding-left: var(--gap-half);
    .article-content & {
      text-decoration-style: dotted;
      text-underline-offset: 0.25rem;
    }
  }

  @media (--sm) {
    padding: var(--gap-double);
    padding-bottom: var(--gap);
  }
}
