.links-block {
  background: var(--theme-pale);
  padding: calc(1.5 * var(--gap-double));

  &--1 {
    --theme: var(--orange);
    background: transparent center / 100% 100% no-repeat url('img/bg-shape-orange.svg');
  }

  &--2 {
    --theme: var(--blue);
    background: transparent center / 100% 100% no-repeat url('img/bg-shape-blue.svg');
  }

  &--3 {
    --theme: var(--purple);
    background: transparent center / 100% 100% no-repeat url('img/bg-shape-purple.svg');
  }


  & > header {
    display: flex;
    align-items: center;
    margin-bottom: var(--gap);
    min-height: 100px;

    & > img {
      max-width: 100px;
      flex-shrink: 0;
      margin-right: var(--gap);
    }
  }

  &__link a {
    border: 3px solid var(--theme);
    border-radius: var(--border-radius);
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--gap);
    text-decoration: underline;
    padding: var(--gap);

    &:hover,
    &:focus {
      text-decoration: none;
    }

    svg {
      color: var(--theme);
      flex-shrink: 0;
      margin-left: var(--gap);
    }
  }
}
