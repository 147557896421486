.contact-info-blocks {
  & > li {
    padding: var(--gap);

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-xlight);
    }
  }
}

.contact-info-block {
  display: flex;
  align-items: flex-start;

  &__image {
    padding-left: var(--gap-half);
    padding-right: var(--gap);
  }

  &__details {
    padding-right: var(--gap);
  }

  &__phone-link {
    display: flex;
    align-items: baseline;
    font-size: var(--fz-h5);
    padding-top: var(--gap-half);
    padding-bottom: var(--gap-half);

    & > svg {
      align-self: center;
    }

    & > * {
      margin-right: var(--gap-half);
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__tag {
    flex-shrink: 0;
    font-size: var(--fz-sm);
    background-color: var(--theme-pale);
    border-radius: var(--border-radius);
    padding: 0.4em 1em;
    font-weight: bold;
    text-align: center;
  }

  &--with-locations {
    flex-direction: column;
    align-items: stretch;

    .contact-info-block {
      &__details {
        padding: var(--gap) var(--gap-double);
      }

      &__locations {
        display: flex;
        flex-wrap: wrap;
      }

      &__location {
        flex-basis: 33%;
        flex-grow: 1;
        min-width: 360px;
        border-top: 1px solid var(--grey-xlight);
        border-right: 1px solid var(--grey-xlight);
        padding: var(--gap) var(--gap-double);
        background-color: white;

        & > div {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: var(--gap-half);
        }

        a {
          text-decoration: none;

          &:hover,
          &:focus {
          text-decoration: underline;
          }
        }

        address {
          font-style: normal;
        }
      }

      &__footer {
        padding: var(--gap-double) var(--gap-double);
        border-top: 1px solid var(--grey-xlight);
      }
    }
  }
}

.contact-form--info {
  display: none;
  visibility: hidden;
}
