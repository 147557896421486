.top-level-menu {
  display: none;
  align-self: flex-end;
  margin-left: auto;
  padding-left: var(--gap);
  padding-top: var(--gap-half);
  padding-bottom: var(--gap-half);

  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__nav {
    display: none;
    position: absolute;
    padding: 0 var(--gap);
    right: 0;
    width: 100%;
    top: calc(100% - var(--gap-double) - var(--gap-half) - 3px);
    z-index: 1;
  }

  &__menu {
    background: var(--white);
    padding: var(--gap-double);
    border-radius: var(--border-radius);
    border: 3px solid var(--theme);
    border-top-right-radius: 0;
    width: 100%;
    max-width: calc(var(--row) - var(--gap-double));
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1580px) {
      max-width: 100%;
      border-top-right-radius: var(--border-radius);
    }
  }

  &__toggle {
    margin-right: var(--gap);
    background: var(--white);
    padding: var(--gap-double) var(--gap);
    border: 2px solid var(--theme);
    border-radius: 10px;
    font-weight: bold;

    & > svg {
      transform: rotate(180deg);
      margin-left: var(--gap);
    }

    &[aria-expanded="true"] {
      border-bottom-color: var(--white);
      border-width: 3px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 2;
      position: relative;

      & > svg {
        transform: none;
      }

      & + .top-level-menu__nav {
        display: block;

        & > ul {
          box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  &__list-item {
    position: relative;
    margin-bottom: var(--gap);

    & > a {
      display: block;
      padding: 0.4rem var(--gap);
      height: 2.75rem;
      border-radius: 5px;
      font-weight: bold;
      background-color: var(--theme-pale);
      border: 1px solid var(--theme);
      display: flex;
      align-items: center;

      &.expanded {
        border-width: 4px;
      }
    }
  }

  &__submenu {
    width: 100%;
    padding: var(--gap);
    columns: 350px auto;
    display: none;

    & > li {
      break-inside: avoid;
    }

    a {
      display: inline-block;
      padding: 0.2rem var(--gap);
      margin-bottom: var(--gap);
    }
  }

  &__submenu-toggle {
    position: absolute;
    top: 0;
    right: 0;
    height: 2.75rem;
    line-height: 2.75rem;
    background-color: var(--white);
    border: 2px solid var(--theme);
    border-radius: 5px;
    font-size: var(--fz-sm);
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    padding: var(--gap);

    .close {
      display: none;
    }

    & > svg {
      margin-left: var(--gap-half);
    }

    &[aria-expanded="true"] {
      border-width: 4px;

      .close {
        display: inline-block;
      }
      .more {
        display: none;
      }

      & > svg {
        .vertical {
          opacity: 0;
        }
      }

      & + ul {
        display: block;
      }
    }
  }

  @media (--md) {
    display: block;
  }
}
