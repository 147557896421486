.accordion {
  background: var(--white);
  color: var(--black-true);
  border-radius: var(--border-radius-xs);
  border: 1px solid var(--grey-mid);
  overflow: hidden;
  margin-bottom: var(--gap);
  box-shadow: 0 0 3px var(--grey-mid);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    padding-right: var(--gap-double);
    padding-left: var(--gap-double);
    flex-grow: 1;
    max-width: 100%;
    margin-right: auto;
    padding-top: var(--gap-half);
    padding-bottom: var(--gap-half);
    line-height: 1;
  }

  &__toggle {
    border-radius: 10px;
    appearance: none;
    background: transparent;
    font-weight: bold;
    border: 2px solid var(--theme);
    padding: var(--gap-half) var(--gap);
    margin: var(--gap-half);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 0.7rem;
    cursor: pointer;

    & > svg {
      color: var(--theme);
      transform: rotate(180deg);
      margin-top: var(--gap-half);
      margin-bottom: var(--gap-half);
    }

    & > .close {
      display: none;
    }

    &[aria-expanded="true"] {
      & > svg {
        transform: none;
      }
      & > .close {
        display: inline-block;
      }
      & > .more {
        display: none;
      }

      & + .accordion__content {
        display: block;
      }
    }
  }

  &__content {
    width: 100%;
    padding: var(--gap) var(--gap-double);
    display: none;

    &--visible {
      display: block;
    }
  }
}

.no-js .accordion {
  &__toggle {
    visibility: hidden;
  }

  &__content {
    display: block;
  }
}
