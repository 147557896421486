.prev-next-stories {
  padding-top: var(--gap);

  a {
    width: 100%;
    font-size: var(--fz-sm);
    align-items: center;
    margin-bottom: var(--gap);

    svg {
      color: $color__red;
      flex-shrink: 0;
    }
  }

  &__previous {
    svg {
      margin-right: var(--gap-half);
    }
  }

  &__next {
    margin-left: auto;
    justify-content: space-between;
    svg {
      margin-left: var(--gap-half);
    }
  }

  @media (--sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__next {
      order: 2;
    }

    a {
      width: auto;
    }
  }
}
