details-dialog {
  position: fixed;
  margin: 10vh auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  max-height: 80vh;
  max-width: 90vw;
  width: 600px;
  background-color: var(--white);
  padding: var(--gap-double);
  box-shadow: 0 0 0 4px var(--purple);
}

.details-with-dialog > summary {
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }
}

.details-with-dialog[open] > summary {
  cursor: default;
}

.details-with-dialog[open] > summary:before {
  content: " ";
  background: rgba(0, 0, 0, 0.5);
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.bsl-dialog {

  &__close {
    position: absolute;
    top: var(--gap);
    right: var(--gap);
    background: none;
    border: none;
    appearance: none;

    svg {
      display: block;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;

    .details-with-dialog[open] & {
      display: block;
    }
  }
}