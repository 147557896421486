.article-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
    font-weight: 700;
  }

  ul:not(.links-grid):not(.three-blob-links),
  ol {
    list-style: none;
    padding-left: var(--gap);
    margin-bottom: var(--gap);

    li {
      margin: 0.2rem 0;
      text-align: left;
    }
  }

  ol {
    list-style: decimal;
  }

  ul:not(.links-grid):not(.three-blob-links) li {
    position: relative;
    padding-left: 0.2rem;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -1rem;
      top: 0.5em;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: var(--theme-dark);
    }
  }

  p {
    margin-bottom: 1rem;
  }

  a:not(.btn):not(.links-grid__link):not(.three-blob-links__link) {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  h1 {
    font-size: var(--fz-h1);
  }
  h2 {
    font-size: var(--fz-h2);
  }
  h3 {
    font-size: var(--fz-h3);
  }
  h4 {
    font-size: var(--fz-h4);
  }
  h5 {
    font-size: var(--fz-h5);
  }
  h6 {
    font-size: var(--fz-base);
  }

  hr {
    border-color: var(--grey-mid);
  }
}
