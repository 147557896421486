/*
 * Hide visually and from screen readers
 */

.hidden,
[hidden] {
  display: none !important;
}

/*
  * Hide visually but include for screen readers
  */
.sr-only,
.screen-reader-text {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/* Full bleed */
.full-bleed {
  max-width: unset;
  width: 100vw;
  margin-left: calc(50% - 50vw);

  &--wrap-children {
    & > * {
      max-width: var(--row);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.block {
  display: block;
}

.w-full {
  width: 100%;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe,
  .youtube-cookie-notice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.youtube-cookie-notice {
  .wp-block-embed-youtube & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  aspect-ratio: 16 / 9;
  border: 2px solid var(--theme);

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.border {
  border: var(--border-standard);
}

.osano-optin-marketing .hide-when-accepted-marketing {
  display: none !important;
}