.article-body {
  & > * {
    max-width: var(--row-md);
    margin-left: auto;
    margin-right: auto;
    &.alignwide {
      max-width: var(--row);
      margin-left: auto;
      margin-right: auto;
    }
    &.alignwide {
      max-width: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
