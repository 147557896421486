.urgent-help {
  padding: var(--gap-double) 0;
  background: var(--theme-pale);
  box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.6);

  & > .wrapper {
    display: flex;
    flex-direction: column;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    margin-left: calc(var(--gap) * -1);
    margin-right: calc(var(--gap) * -1);

    & > li {
      padding-left: var(--gap-half);
      padding-right: var(--gap-half);
      margin-bottom: var(--gap-double);
      position: relative;
      flex: 1 1 0;
      display: grid;

      @media (--tiny) {
        padding-left: var(--gap);
        padding-right: var(--gap);
      }
    }
  }

  &__button {
    --shadow-1: 0 0 8px rgba(0, 0, 0, 0.16);
    outline-color: var(--orange);
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--white);
    text-decoration: none;
    font-weight: bold;
    padding: var(--gap-double) var(--gap);
    border-radius: var(--border-radius-sm);
    margin: var(--gap) 0;
    box-shadow: 2px solid var(--orange), var(--shadow-1);

    & > img {
      margin-bottom: var(--gap);
    }

    & > span {
      font-size: var(--fz-sm);
      text-align: center;
    }

    & > svg {
      margin-left: auto;
      color: var(--orange);
      display: none;
    }

    @media (--md) {
      flex-direction: row;

      & > img {
        margin-right: var(--gap);
        margin-bottom: 0;
      }

      & > span {
        font-size: var(--fz-base);
        text-align: left;
        margin-right: var(--gap);
      }

      & > svg {
        display: block;
      }
    }

    @media (--lg) {
      & > span {
        font-size: var(--fz-h4);
      }
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 0 4px var(--orange), var(--shadow-1);
    }

    &[aria-selected="true"] {
      box-shadow: 0 0 0 6px var(--orange), var(--shadow-1);
    }

    &--bordered {
      border: 2px solid var(--orange);
    }
  }

  &__tab {
    padding-top: var(--gap);
    padding-bottom: var(--gap);
  }

  &__banner {
    background: var(--purple-dark);
    color: var(--white);
    border-radius: var(--border-radius-sm);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
    margin-top: var(--gap);
    margin-bottom: var(--gap);
    padding: var(--gap);
    display: flex;
    align-items: center;
    order: -1;

    & > img {
      display: block;
      margin-right: var(--gap);
    }

    & > div {
      max-width: var(--row-sm);
    }

    @media (--sm) {
      padding: var(--gap-double);
      order: unset;

      & > img {
        margin-right: var(--gap-double);
      }
    }
  }
}
