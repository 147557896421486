.three-blob-links {
  list-style: none;
  padding: 0;

  & > li {
    list-style: none;
    margin-bottom: var(--gap);
    width: 100%;
    display: grid;
  }

  @media (--md) {
    margin-left: calc(var(--gap) * -1);
    margin-right: calc(var(--gap) * -1);
    display: flex;
    flex-wrap: wrap;

    & > li {
      margin-left: var(--gap);
      margin-right: var(--gap);
      flex-grow: 1;
      flex-basis: calc(33% - calc(var(--gap) * 2));
      min-width: 320px;
    }
  }

  &__link {

    &--orange {
      --theme: var(--orange);
      background: transparent center / 100% 100% no-repeat url('/wp-content/themes/mindwell/img/bg-shape-orange.svg');
    }

    &--green {
      --theme: var(--green);
      background: transparent center / 100% 100% no-repeat url('/wp-content/themes/mindwell/img/bg-shape-green.svg');
    }

    &--blue {
      --theme: var(--blue);
      background: transparent center / 100% 100% no-repeat url('/wp-content/themes/mindwell/img/bg-shape-blue.svg');
    }

    &--purple {
      --theme: var(--purple);
      background: transparent center / 100% 100% no-repeat url('/wp-content/themes/mindwell/img/bg-shape-purple.svg');
    }

    padding: var(--gap-double);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--title-font);
    font-weight: bold;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    & > span {
      flex-grow: 1;
      text-align: left;
    }

    & > svg {
      flex-shrink: 0;
      margin-left: var(--gap);
      color: var(--theme);
    }
  }

  &__image {
    flex-shrink: 0;
    margin-right: var(--gap-double);
    max-width: 50px;

    @media (--tiny) {
      max-width: 100%;
    }
  }
}
