.resource-link-block {
  background: var(--theme-pale);
  border-radius: var(--border-radius);
  border: 2px solid var(--theme);
  margin-top: var(--gap);
  margin-bottom: var(--gap);
  padding: 0 var(--gap-double);

  &__links {
    & > a {
      display: inline-block;
      margin-right: var(--gap-double);
    }
  }

  & > * {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
  }
}
