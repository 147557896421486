.breadcrumbs {
  padding: var(--gap) 0;
  border-top: 1px solid var(--grey-mid);
  border-bottom: 1px solid var(--grey-mid);

  ul {
    --gap-modifier: 0.5;

    li {
      margin-right: calc(var(--gap-modifier) * var(--gap-half));
      position: relative;
      display: inline;
      vertical-align: middle;

      a {
        display: inline-flex;
        padding: calc(var(--gap-modifier) * var(--gap-half));
        font-size: var(--fz-sm);

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      &:not(:first-child) {
        padding-left: calc(var(--gap-modifier) * var(--gap));

        &:before {
          content: "/";
          color: var(--theme);
          display: inline-block;
          margin-right: calc(var(--gap-modifier) * var(--gap));
        }
      }

      &:last-child {
        a {
          font-weight: bold;
          pointer-events: none;
          text-decoration: none;
        }
      }
    }

    @media (--tiny) {
      --gap-modifier: 1;
    }
  }
}
