.posts-grid {
  list-style: none;
  padding: 0;

  & > li {
    list-style: none;
    margin-bottom: var(--gap-double);
    width: 100%;
  }

  @media (--md) {
    margin-left: calc(var(--gap-double) * -1);
    margin-right: calc(var(--gap-double) * -1);
    display: flex;
    flex-wrap: wrap;

    & > li {
      margin-left: var(--gap-double);
      margin-right: var(--gap-double);
      flex-grow: 0;
      width: calc(50% - calc(var(--gap-double) * 2));
    }
  }

  @media (--xl) {
    & > li {
      width: calc(33% - calc(var(--gap-double) * 2));
    }
  }

  @supports (display: grid) {
    display: grid;
    justify-content: space-between;
    gap: calc(var(--gap-double) * 1) calc(var(--gap-double) * 2);
    margin-bottom: var(--gap-double);

    grid-template-columns: 1fr;

    & > li {
      margin: 0;
      width: 100%;
      display: grid;
    }

    @media (--tiny) {
      grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    }

    @media (--md) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
