.contact-us {
  &__intro.wrapper {
    flex-direction: column;

    @media (--md) {

      .page-heading & {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      & > p,
      & > div {
        padding-right: var(--gap-double);

        a {
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }

  &__uh-link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-size: var(--fz-h5);
    font-family: var(--title-font-stack);
    font-weight: bold;
    color: var(--white);
    padding: var(--gap-double);
    background: transparent center / 100% 100% no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='461.677' height='157.386' viewBox='0 0 461.677 157.386'%3E%3Cpath d='M451.42 6.115c13.518 11.739 11.864 93.876 5.386 124.531s-60.56 21.799-115.158 21.951-180.359 2.2-183.929 2.2-113.114 7.089-137.848-2.2S-2.321 44.42 5.244 28.471 27.615 2.133 58.891 6.114s218.3.2 260.508-1.537S437.903-5.625 451.42 6.115z' fill='%237e59b8'/%3E%3C/svg%3E");

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    & > svg {
      margin-left: var(--gap);
      flex-shrink: 0;
    }

    @media (--lg) {
      padding: calc(1.5 * var(--gap-double));
    }
  }
}
