.footer {
  margin-top: var(--gap);

  &__sillhouettes {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__top {
    border-top: 1px solid var(--black);
    text-align: center;
    padding: var(--gap);
  }

  &__middle {
    border-top: 1px solid var(--grey-mid);
    border-bottom: 1px solid var(--grey-mid);
    text-align: center;
    padding: var(--gap);
  }

  &__partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__bottom {
    padding: var(--gap);
    padding-bottom: 3rem;
    font-size: var(--fz-sm);

    @media (--lg) {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
  }

  &__nav {
    flex-grow: 1;
  }

  &__menu {
    columns: 300px auto;

    a {
      display: inline-flex;
      align-items: center;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      svg {
        color: var(--green-dark);
        flex-shrink: 0;
        margin-right: var(--gap);
      }
    }
  }

  &__bottom-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (--lg) {
      align-items: flex-end;
    }
  }

  &__socials {
    display: flex;

    li {
      margin-top: var(--gap);
      margin-right: var(--gap);

      @media (--lg) {
        margin: 0 0 var(--gap) var(--gap);
      }
    }
  }

  &__bottom-meta {
    display: flex;

    a {
      margin-left: var(--gap);
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
