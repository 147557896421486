.search-bar {
  border: 2px solid var(--grey-xlight);
  border-radius: var(--border-radius);
  position: relative;
  background-color: var(--white);
  padding: 5px;
  display: flex;
  align-items: stretch;
  
  & > input {
    padding: var(--gap-half);
    appearance: none;
    border: none;
    background: transparent;
    flex-grow: 1;
    border-radius: var(--border-radius);
    line-height: 1;
  }

  & > button {
    appearance: none;
    background-color: var(--green);
    border-radius: 50%;
    border: none;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--green-dark);
    }

    & > svg {
      position: relative;
      bottom: 2px;
      right: 1px;
    }
  }

  &--lg {
    max-width: 100%;
    width: 440px;
  }
}
