.mg {
  margin: var(--gap);

  &-y {
    margin-top: var(--gap);
    margin-bottom: var(--gap);

    &-sm {
      margin-top: calc(var(--gap) / 2);
      margin-bottom: calc(var(--gap) / 2);
    }
    &-lg {
      margin-top: calc(var(--gap) * 2);
      margin-bottom: calc(var(--gap) * 2);
    }
  }

  &-x {
    margin-left: var(--gap);
    margin-right: var(--gap);

    &-sm {
      margin-left: calc(var(--gap) / 2);
      margin-right: calc(var(--gap) / 2);
    }

    &-lg {
      margin-left: calc(var(--gap) * 2);
      margin-right: calc(var(--gap) * 2);
    }

    &-auto {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-b {
    margin-bottom: var(--gap);

    &-sm {
      margin-bottom: calc(var(--gap) / 2);
    }
    &-lg {
      margin-bottom: calc(var(--gap) * 2);
    }
  }

  &-t {
    margin-top: var(--gap);

    &-sm {
      margin-top: calc(var(--gap) / 2);
    }
    &-lg {
      margin-top: calc(var(--gap) * 2);
    }
  }

  &-r {
    margin-right: var(--gap);

    &-sm {
      margin-right: calc(var(--gap) / 2);
    }
    &-lg {
      margin-right: calc(var(--gap) * 2);
    }
  }

  &-l {
    margin-left: var(--gap);

    &-sm {
      margin-left: calc(var(--gap) / 2);
    }
    &-lg {
      margin-left: calc(var(--gap) * 2);
    }
    &-auto {
      margin-left: auto;
    }
  }
}

.pad {
  padding: var(--gap);

  &-y {
    padding-top: var(--gap);
    padding-bottom: var(--gap);

    &-sm {
      padding-top: calc(var(--gap) / 2);
      padding-bottom: calc(var(--gap) / 2);
    }
    &-lg {
      padding-top: calc(var(--gap) * 2);
      padding-bottom: calc(var(--gap) * 2);
    }
  }

  &-x {
    padding-left: var(--gap);
    padding-right: var(--gap);

    &-sm {
      padding-left: calc(var(--gap) / 2);
      padding-right: calc(var(--gap) / 2);
    }

    &-lg {
      padding-left: calc(var(--gap) * 2);
      padding-right: calc(var(--gap) * 2);
    }
  }

  &-b {
    padding-bottom: var(--gap);

    &-sm {
      padding-bottom: calc(var(--gap) / 2);
    }
    &-lg {
      padding-bottom: calc(var(--gap) * 2);
    }
  }

  &-t {
    padding-top: var(--gap);

    &-sm {
      padding-top: calc(var(--gap) / 2);
    }
    &-lg {
      padding-top: calc(var(--gap) * 2);
    }
  }
}
