.with-sidebar {
  overflow: hidden;
}

.with-sidebar > * {
  display: flex;
  flex-flow: wrap-reverse;
  margin: calc(var(--gap) * -1);
}

.with-sidebar > * > * {
  margin: calc(var(--gap));
  flex-basis: 20em;
  flex-grow: 1;
}

.with-sidebar > * > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(60% - var(--gap-double));
}
