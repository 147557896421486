.filter-group {
  border-bottom: 1px solid var(--grey-xlight);
  padding-bottom: var(--gap);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &__fieldset {
    border: 0;
    margin: 0 0 var(--gap) 0;
    padding: 0;
  }

  &__radios {
    display: flex;
    align-items: center;

    & > * {
      margin-right: var(--gap-double);
    }
  }

  &__display-label {
    font-weight: bold;
    font-family: var(--title-font-stack);
  }

  .btn {
    display: none;
  }
}
.no-js .filter-group .btn {
  display: inline-flex;
}