.newsletter-signup {
  background: var(--white);
  padding: var(--gap);
  border: 4px solid var(--theme);
  border-radius: var(--border-radius);

  input {
    border-radius: var(--border-radius);
  }
}
