.header {
  & > .wrapper {
    padding: var(--gap-half);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__home-link {
    margin-right: auto;
  }

  &__search-and-controls {
    margin-top: var(--gap-half);
    margin-bottom: var(--gap-half);
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__search {
    margin-left: auto;
  }

  &__info-button {
    border: none;
    padding: 0;
    appearance: none;
    background: none;
    margin: 0 var(--gap-half);

    & > img {
      display: block;
    }
  }

  &__nhs-logo {
    margin-left: var(--gap);
  }

  &__divider {
    display: block;
    width: 100%;
    height: 1px;
    border-top: 1px solid var(--grey-mid);
  }

  &__nav-toggle {
    margin-left: var(--gap);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    background: none;
    border: none;
    padding: var(--gap-half) 0;
    color: var(--purple-dark);

    &[aria-expanded="true"] {
      & + .header__nav {
        display: block;
        margin-top: var(--gap);
        margin-bottom: var(--gap);
      }
    }
  }

  &__nav {
    display: none;
    order: 3;
    width: 100%;
    border-top: 1px solid var(--grey-mid);
    padding-top: var(--gap);
  }

  &__help {
    margin-left: var(--gap-half);
  }

  &__accessibility {
    margin-left: auto;
  }

  &__buttons {
    display: inline-flex;
    margin-left: auto;
    width: auto;
  }

  &__alert-text {
    padding: var(--gap) var(--gap-half);
    background-color: var(--purple-dark);
    color: var(--white);
    text-align: center;

    .page-template-home & {
      border-bottom: 1px solid var(--white);
    }
  }

  @media (--sm) {
    &__search-and-controls {
      margin-top: 0;
      margin-bottom: 0;
      width: auto;
    }

    &__nhs-logo {
      order: 3;
    }
  }

  @media (--md) {
    &__nav {
      display: block;
      order: 0;
      width: auto;
      padding-top: 0;
      border: none;
    }

    &__nav-toggle {
      display: none;
    }
  }
}
