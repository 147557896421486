.service-info-disclosure {
  background: var(--white);
  color: var(--black-true);
  border-radius: var(--border-radius-sm);
  border: 2px solid var(--theme);
  overflow: hidden;
  display: block;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: var(--gap);

  @media (--sm) {
    display: flex;
  }

  .services-directory__results & {
    border-color: var(--theme-dark);
  }

  &__title-area {
    padding: 0;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;

    @media (--md) {
      max-width: calc(100% - 80px - var(--gap));
    }

    & > img {
      margin-left: var(--gap-half);
      margin-right: var(--gap);
    }
  }

  &__title {
    padding-right: var(--gap-double);
    flex-grow: 1;
    flex-basis: 36ch;
    max-width: 100%;
    margin-right: auto;
    padding-top: var(--gap-half);
    padding-bottom: var(--gap-half);
  }

  &__title-holder {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--gap);
    width: 100%;
    @media (--sm) {
      width: 33.33%;
      max-width: 300px;
      flex: 0 0 33.33%;
    }
    @media (--lg) {
      max-width: 33.33%;
    }
    min-width: 300px;
  }

  &__extra-info {
    flex-basis: 40%;
    flex-grow: 1;
    display: block;
    align-items: center;
    padding: var(--gap-half) var(--gap);

    @media (--lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--gap);
    }

    & > p {
      max-width: 75%;
      margin-bottom: 10px;
      @media (--lg) {
        max-width: 100%;
        margin-bottom: 0px;
      }
    }

    svg {
      color: var(--theme-dark);
    }
  }

  &__location {
    @media (--lg) {
      grid-column: 2;
    }
  }

  &__tag {
    background-color: var(--red-pale);
    border-radius: var(--border-radius);
    padding: 0.4em 1em;
    font-weight: bold;
  }

  &__toggle {
    border-radius: 10px;
    appearance: none;
    background: transparent;
    font-weight: bold;
    border: 2px solid;
    padding: var(--gap-half) var(--gap);
    margin: var(--gap-half);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;

    @media (--sm) {
      position: absolute;
      right: 15px;
    }

    @media (--md) {
      position: static;
    }

    & > svg {
      transform: rotate(180deg);
      margin-top: var(--gap-half);
      margin-bottom: var(--gap-half);
    }

    & > .close {
      display: none;
    }

    &[aria-expanded="true"] {
      & > svg {
        transform: none;
      }
      & > .close {
        display: inline-block;
      }
      & > .more {
        display: none;
      }

      & + .service-info-disclosure__content {
        display: block;
      }
    }
  }

  &__content {
    border-top: 1px solid var(--grey-xlight);
    width: 100%;
    display: none;
  }

  @media (--md) {
    &__title {
      padding: 0;
    }
  }
}

.no-js .service-info-disclosure {
  &__toggle {
    visibility: hidden;
  }

  &__content {
    display: block;
  }
}

.services-directory__results {
  .service-info-disclosure {
    border-color: var(--theme-dark);

    &__title-holder {
      color: var(--white);
      background-color: var(--theme-dark);
    }

    &__toggle {
      color: var(--white);
      background-color: var(--theme-dark);
    }

    &__extra-info svg,
    &__title svg {
      color: var(--theme-dark);
    }
  }
}
