.home-carousel {
  overflow: hidden;
  position: relative;

  /* Embla carousel classes */
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }
  &.is-dragging {
    cursor: grabbing;
  }

  ul {
    display: flex;
  }

  li {
    min-width: 100%;
    position: relative;
  }

  &__slide {
    padding-top: var(--gap-half);
    padding-bottom: var(--gap-half);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  &__curve {
    display: block;
    width: 100%;
    color: var(--theme-dark);
  }

  &__text-container {
    position: relative;
    margin-top: var(--gap-half);
    margin-bottom: calc(var(--gap-double) * 1.5);
    padding-left: var(--gap);
    padding-right: var(--gap);

    .speech-bubble {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: none;
    }
  }

  &__text {
    position: relative;
    padding: var(--gap) var(--gap-double);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--white);
    box-shadow: 0 0 0 10px var(--theme);
    border-radius: var(--border-radius);
    min-height: 270px;
  }

  &__image {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
    display: none;
  }

  @media (--md) {
    &__slide {
      flex-direction: row;
    }

    &__text-container {
      position: relative;
      flex-basis: 60%;
      margin-right: var(--gap-double);
      margin-bottom: var(--gap-half);
      padding-left: 0;
      padding-right: 0;

      .speech-bubble {
        display: block;
      }
    }

    &__text {
      padding-right: calc(var(--gap-double) * 4);
      background: none;
      box-shadow: none;
    }

    &__image {
      display: block;
      max-width: 40%;
    }
  }
}

.embla__dots {
  position: absolute;
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  left: 0;
  bottom: 1.5rem;
}

.embla__dot {
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 4px solid var(--theme-dark);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  background-color: var(--white);

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px var(--white);
  }

  &.is-selected {
    background-color: var(--theme-dark);
    border-color: var(--white);
  }
}
