.tease {
  border: 2px solid var(--grey-xlight);
  border-radius: var(--border-radius-sm);
  position: relative;
  display: block;
  padding: var(--gap);

  & > header {
    display: flex;
    align-items: center;
  }

  &__post-type {
    display: inline-block;
    padding: 0.2rem 1rem;
    border-radius: var(--border-radius);
    background-color: var(--theme-pale);
    font-size: var(--fz-sm);
    font-weight: bold;
    font-family: var(--title-font-stack);
    margin-right: var(--gap);
    margin-left: -0.25rem;
  }

  &__preview {
    font-size: var(--fz-sm);

    a {
      font-weight: bold;
    }
  }

  a {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
